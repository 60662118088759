<template>
    <svg
        viewBox="0 0 10 11"
        :width="size"
        :height="size"
        class="paypal-icon"
    >
        <path d="M9.90662 4.0207C9.90662 5.0711 9.47034 5.9195 8.59968 6.56398C7.72903 7.20846 6.51243 7.52974 4.94795 7.52974H4.08306L3.41998 10.4058H1.13477L3.24509 1.57938H6.33945C6.91604 1.57938 7.41768 1.61978 7.8482 1.69865C8.27679 1.77753 8.64773 1.91412 8.95909 2.10843C9.26661 2.30273 9.50301 2.55475 9.66445 2.86449C9.8259 3.1723 9.90662 3.55898 9.90662 4.0207Z" />
        <path d="M3.49688 10.5H1.01562L3.17207 1.48306H6.33947C6.91799 1.48306 7.43115 1.52346 7.86359 1.60234C8.3018 1.68314 8.6862 1.8255 9.00716 2.02366C9.32813 2.22566 9.57799 2.49307 9.74712 2.81627C9.91625 3.13947 10.0008 3.54347 10.0008 4.01865C10.0008 5.09599 9.54916 5.97902 8.65544 6.63889C7.77134 7.29299 6.52398 7.62388 4.94605 7.62388H4.15612L3.49688 10.5ZM1.25587 10.3115H3.34505L4.00813 7.43535H4.94797C6.48362 7.43535 7.69446 7.11599 8.54397 6.48691C8.96488 6.17525 9.28585 5.80972 9.49534 5.40188C9.70484 4.99403 9.81055 4.52846 9.81055 4.02058C9.81055 3.57617 9.73175 3.20103 9.57799 2.90476C9.42423 2.61042 9.19936 2.36994 8.90722 2.18526C8.60739 1.99865 8.24414 1.8659 7.829 1.78895C7.40809 1.712 6.90645 1.6716 6.33755 1.6716H3.32006L1.25587 10.3115Z" />
        <path d="M8.891 3.03764C8.891 4.08804 8.45471 4.93644 7.58406 5.58092C6.71341 6.2254 5.4968 6.54668 3.93232 6.54668H3.06744L2.40436 9.42278H0.119141L2.1545 0.594391H5.32383C5.90042 0.594391 6.40205 0.634791 6.83257 0.713667C7.26117 0.792544 7.63211 0.929135 7.94347 1.12344C8.25098 1.31775 8.48739 1.56977 8.64883 1.8795C8.81028 2.18731 8.891 2.574 8.891 3.03764Z" />
        <path d="M2.47934 9.51692H0L2.07765 0.5H5.32385C5.90236 0.5 6.41553 0.5404 6.84797 0.619277C7.28618 0.700077 7.67057 0.842439 7.99154 1.04059C8.31251 1.24259 8.56236 1.51 8.7315 1.8332C8.90063 2.15641 8.9852 2.56041 8.9852 3.03559C8.9852 4.11293 8.53353 4.99596 7.63982 5.65583C6.75571 6.30992 5.50836 6.64082 3.93042 6.64082H3.14049L2.47934 9.51692ZM0.238324 9.32647H2.32942L2.9925 6.45036H3.93234C5.468 6.45036 6.67884 6.13101 7.52835 5.50192C7.94926 5.19026 8.27022 4.82474 8.47972 4.41689C8.68921 4.00904 8.79492 3.54348 8.79492 3.03559C8.79492 2.59119 8.71612 2.21604 8.56236 1.91978C8.40861 1.62543 8.18373 1.38496 7.8916 1.20027C7.59177 1.01366 7.22852 0.880915 6.81337 0.803963C6.39246 0.72701 5.89083 0.68661 5.32193 0.68661H2.22756L0.238324 9.32647ZM6.54238 3.35109C6.52508 3.62043 6.47126 3.81858 6.30597 4.04752C6.14261 4.27645 5.95041 4.42458 5.69287 4.54771C5.53719 4.62081 5.37574 4.66891 5.20661 4.69199C5.03748 4.71508 4.83567 4.72855 4.59927 4.72855H3.46338L4.09956 2.4469H5.13165C5.39496 2.4469 5.60638 2.45075 5.7659 2.4873C5.92542 2.52193 6.05612 2.57003 6.15414 2.62966C6.2906 2.71046 6.40015 2.80858 6.46357 2.93363C6.54238 3.07984 6.55198 3.1741 6.54238 3.35109Z" />
    </svg>
</template>
<script>
export default {
    name: 'PaypalIcon',
    props: {
        size: {
            type: [Number, String],
            default: 16
        }
    }
}
</script>
