<template>
    <svg
        viewBox="0 0 15 11"
        :width="width"
        :height="height"
        class="eye-icon"
    >
        <path d="M14.8902 5.23057C14.7459 5.02501 11.3911 0.580566 7.50208 0.580566C3.80536 0.580566 0.274242 5.02501 0.124663 5.2139C0.0440012 5.31769 0 5.44711 0 5.58057C0 5.71402 0.0440012 5.84344 0.124663 5.94723C0.274242 6.13612 3.80536 10.5806 7.50208 10.5806C11.1988 10.5806 14.7353 6.13612 14.8795 5.91946C14.9556 5.82263 14.998 5.70195 14.9999 5.57694C15.0019 5.45193 14.9632 5.32989 14.8902 5.23057ZM7.50208 9.46946C4.89514 9.46946 2.18671 6.63057 1.29458 5.58057C2.18671 4.55279 4.89514 1.69168 7.50208 1.69168C10.2533 1.69168 12.8709 4.53057 13.7203 5.58057C12.8442 6.59168 10.1731 9.46946 7.50208 9.46946Z" />
        <path d="M7.5021 2.80273C6.97382 2.80273 6.4574 2.96565 6.01815 3.27087C5.5789 3.5761 5.23654 4.00993 5.03438 4.5175C4.83221 5.02508 4.77932 5.58359 4.88238 6.12243C4.98544 6.66127 5.23983 7.15622 5.61339 7.5447C5.98694 7.93318 6.46287 8.19774 6.981 8.30492C7.49914 8.4121 8.03619 8.35709 8.52426 8.14685C9.01233 7.9366 9.42949 7.58057 9.72299 7.12376C10.0165 6.66696 10.1731 6.12991 10.1731 5.58051C10.1731 4.8438 9.89173 4.13726 9.39081 3.61633C8.88989 3.09539 8.2105 2.80273 7.5021 2.80273ZM7.5021 7.24718C7.18513 7.24718 6.87528 7.14943 6.61173 6.9663C6.34818 6.78316 6.14276 6.52286 6.02147 6.21832C5.90017 5.91377 5.86843 5.57866 5.93027 5.25536C5.9921 4.93206 6.14474 4.63509 6.36887 4.402C6.593 4.16891 6.87856 4.01018 7.18944 3.94587C7.50032 3.88156 7.82255 3.91457 8.1154 4.04071C8.40824 4.16686 8.65853 4.38048 8.83463 4.65456C9.01073 4.92864 9.10472 5.25088 9.10472 5.58051C9.10472 6.02254 8.93588 6.44646 8.63533 6.75902C8.33478 7.07158 7.92714 7.24718 7.5021 7.24718Z" />
    </svg>
</template>

<script>
    export default {
        name: 'EyeIcon',
        props: {
            width: {
                type: [Number, String],
                default: 15
            },
            height: {
                type: [Number, String],
                default: 11
            }
        }
    }
</script>
