<template>
    <svg
        :width="width"
        :height="height"
        class="venmo-colored-icon"
        style="enable-background: new 0 0 64 64"
        viewBox="0 0 64 64"
    >
        <g id="Venmo">
            <path
                class="st0"
                d="M2,8c0-3.3,2.7-6,6-6h48c3.3,0,6,2.7,6,6v48c0,3.3-2.7,6-6,6H8c-3.3,0-6-2.7-6-6V8z"
            />
            <path
                class="st1"
                d="M8,0C3.6,0,0,3.6,0,8v48c0,4.4,3.6,8,8,8h44v-3h9V20h-1c0,12.2-7.7,22.8-19,28.2c-3.5,2.3-9.8,3.8-17,3.8   c-11,0-20-3.6-20-8V8c0-2.2,1.8-4,4-4h12V0H8z"
            />
            <path
                class="st2"
                d="M48,12l-12,3c0,0,1.5,2.5,1.5,6c0,4.4-4,14-7,18L27,13l-13,1.5L20,52h15c0.5-0.7,7-10,7-10   c5.5-8,8-15.5,8-21.5C50,15.7,48.7,12.8,48,12z"
            />
            <path
                class="st3"
                d="M56,4H16V0h40c4.4,0,8,3.6,8,8v48c0,4.4-3.6,8-8,8h-8v-4h8c2.2,0,4-1.8,4-4V8C60,5.8,58.2,4,56,4z"
            />
        </g>
    </svg>
</template>
<script>
export default {
    name: "VenmoColoredIcon",
    props: {
        width: {
            type: [Number, String],
            default: 32,
        },
        height: {
            type: [Number, String],
            default: 32,
        },
    },
};
</script>

<style scoped>
.st0 {
    fill: #57cef3;
}
.st1 {
    fill: #3d95ce;
}
.st2 {
    fill: #ffffff;
}
.st3 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #314a52;
}
</style>
