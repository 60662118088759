<template>
    <button
        :disabled="disabled"
        :type="buttonType"
        class="primary-button"
    >
        <loader
            v-if="isLoading"
            size="mini"
            loaderColor="white"
            class="s-align-5 mr-1 btn-loader"
        />
        <slot></slot>
    </button>
</template>

<script>
export default {
    name: 'PrimaryButton',
    props: {
        buttonType: {
            type: String,
            default: 'button',
        },
        disabled: {
            type: Boolean,
            default: false
        },
        isLoading: {
            type: Boolean,
            default: false
        }
    }
}
</script>
