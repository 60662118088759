<template>
    <svg
        viewBox="0 0 700 700"
        :width="size"
        :height="size"
        class="link-icon"
    >
        <path d="m382.9 412.07c4.3477 4.3711 6.7852 10.285 6.7852 16.449s-2.4375 12.078-6.7852 16.449l-49.465 49.465v0.003906c-35.734 33.297-86.238 45.562-133.27 32.367-47.023-13.195-83.773-49.945-96.969-96.969-13.195-47.027-0.92969-97.531 32.367-133.27l49.465-49.465h0.003906c6.0039-5.1445 14.207-6.8984 21.793-4.6523 7.582 2.2422 13.516 8.1719 15.758 15.758 2.2422 7.582 0.49219 15.785-4.6523 21.793l-49.469 49.465c-23.59 23.594-32.805 57.98-24.168 90.203 8.6367 32.227 33.809 57.398 66.035 66.035 32.223 8.6367 66.609-0.57812 90.203-24.168l49.465-49.469c4.3711-4.3438 10.285-6.7852 16.449-6.7852 6.1641 0 12.078 2.4414 16.449 6.7852zm181.54-346.5c-26.25-26.219-61.832-40.941-98.934-40.941s-72.684 14.723-98.934 40.941l-49.465 49.469c-5.1445 6.0039-6.8984 14.207-4.6523 21.793 2.2422 7.582 8.1719 13.516 15.758 15.758 7.582 2.2422 15.785 0.49219 21.793-4.6523l49.465-49.465v-0.003906c23.594-23.59 57.98-32.805 90.203-24.168 32.227 8.6367 57.398 33.809 66.035 66.035 8.6367 32.223-0.57812 66.609-24.168 90.203l-49.469 49.465c-4.3438 4.3711-6.7852 10.285-6.7852 16.449 0 6.1641 2.4414 12.078 6.7852 16.449 4.3711 4.3477 10.285 6.7852 16.449 6.7852s12.078-2.4375 16.449-6.7852l49.465-49.465h0.003906c26.219-26.25 40.941-61.832 40.941-98.934s-14.723-72.684-40.941-98.934zm-280 115.5c-5.8789-5.9609-14.488-8.332-22.59-6.2227-8.0977 2.1094-14.457 8.3789-16.68 16.449-2.2266 8.0703 0.027344 16.715 5.9023 22.672l164.97 164.97c4.3711 4.3438 10.285 6.7852 16.449 6.7852s12.078-2.4414 16.449-6.7852c4.3438-4.3711 6.7852-10.285 6.7852-16.449s-2.4414-12.078-6.7852-16.449z"/>
    </svg>
</template>

<script>
    export default {
        name: 'UnlinkIcon',
        props: {
            size: {
                type: [Number, String],
                default: 10
            }
        }
    }
</script>
