<template>
    <svg
        viewBox="0 0 11 9"
        :width="size"
        :height="size"
        class="email-icon"
    >
        <path d="M1.95161 9H9.04839C9.56599 9 10.0624 8.80685 10.4284 8.46303C10.7944 8.11921 11 7.6529 11 7.16667V1.83333C11 1.3471 10.7944 0.880787 10.4284 0.536971C10.0624 0.193154 9.56599 0 9.04839 0H1.95161C1.43401 0 0.937612 0.193154 0.571614 0.536971C0.205616 0.880787 0 1.3471 0 1.83333V7.16667C0 7.6529 0.205616 8.11921 0.571614 8.46303C0.937612 8.80685 1.43401 9 1.95161 9ZM9.04839 8H1.95161C1.71634 8 1.4907 7.9122 1.32434 7.75592C1.15798 7.59964 1.06452 7.38768 1.06452 7.16667V3.28167L3.90323 5.18667C4.36911 5.4994 4.92739 5.66752 5.5 5.66752C6.0726 5.66752 6.63089 5.4994 7.09677 5.18667L9.93548 3.28167V7.16667C9.93548 7.38768 9.84202 7.59964 9.67566 7.75592C9.5093 7.9122 9.28366 8 9.04839 8ZM1.95161 1H9.04839C9.28366 1 9.5093 1.0878 9.67566 1.24408C9.84202 1.40036 9.93548 1.61232 9.93548 1.83333V2.05167L6.47758 4.37167C6.19267 4.56231 5.85157 4.66476 5.50177 4.66476C5.15197 4.66476 4.81088 4.56231 4.52597 4.37167L1.06452 2.05167V1.83333C1.06452 1.61232 1.15798 1.40036 1.32434 1.24408C1.4907 1.0878 1.71634 1 1.95161 1Z" />
    </svg>
</template>

<script>
    export default {
        name: 'EmailIcon',
        props: {
            size: {
                type: [Number, String],
                default: 11
            }
        }
    }
</script>
