<template>
    <svg
        viewBox="0 0 13 13"
        :width="size"
        :height="size"
        class="analytics-icon"
    >
        <g>
            <path d="M10.6174 13H12.0789C12.587 13 13 12.558 13 12.0143V0.984872C13 0.44121 12.587 0 12.0789 0H10.6174C10.1078 0 9.69488 0.44121 9.69488 0.984872V12.0142C9.69488 12.558 10.1078 13 10.6174 13Z" />
            <path d="M5.77007 13H7.2315C7.73961 13 8.15258 12.566 8.15258 12.0323V5.30036C8.15258 4.76651 7.73961 4.33333 7.2315 4.33333H5.77007C5.26196 4.33333 4.84749 4.76651 4.84749 5.30036V12.0323C4.84749 12.566 5.26196 13 5.77007 13Z" />
            <path d="M0.922577 13H2.38401C2.89362 13 3.30508 12.5603 3.30508 12.0195V8.0514C3.30508 7.51059 2.89362 7.07018 2.38401 7.07018H0.922577C0.412966 7.07018 0 7.51059 0 8.0514V12.0195C0 12.5603 0.412966 13 0.922577 13Z" />
        </g>
    </svg>
</template>

<script>
    export default {
        name: 'AnalyticsIcon',
        props: {
            size: {
                type: [Number, String],
                default: 13
            }
        }
    }
</script>
