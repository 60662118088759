<template>
    <svg
        viewBox="0 0 64 64"
        :width="size"
        :height="size"
        class="linkedin-colored-icon"
    >
        <g data-name="14-linkedin" id="_14-linkedin">
            <rect class="cls-1" height="64" rx="11.2" ry="11.2" width="64" />
            <rect
                class="cls-2"
                height="32.72"
                width="9.03"
                x="8.99"
                y="23.54"
            />
            <path
                class="cls-2"
                d="M48.2,23.54C41.54,21,36.72,25.3,34.66,27.7V23.54h-9V56.26h9V39a8.45,8.45,0,0,1,2.23-5.92,4.75,4.75,0,0,1,3.41-1.67A5.42,5.42,0,0,1,44.24,33a6.13,6.13,0,0,1,1.7,4.35V56.26H56.1V36S57.23,26.92,48.2,23.54Z"
            />
            <circle class="cls-2" cx="13.5" cy="13.38" r="5.64" />
        </g>
    </svg>
</template>

<script>
export default {
    name: "LinkedinColoredIcon",
    props: {
        size: {
            type: [Number, String],
            default: 32,
        },
    },
};
</script>

<style scoped>
.cls-1 {
    fill: #007fb5;
}
.cls-2 {
    fill: #fff;
}
</style>
