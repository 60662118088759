<template>
    <svg
        viewBox="0 0 37 37"
        :width="width"
        :height="height"
        class="not-found-icon"
    >
        <path d="M33.1615 1.5145e-08H3.83814C2.8202 1.5145e-08 1.84396 0.40382 1.12417 1.12262C0.404374 1.84143 0 2.81633 0 3.83287V33.1671C0 34.1837 0.404374 35.1586 1.12417 35.8774C1.84396 36.5962 2.8202 37 3.83814 37H33.1615C34.1795 37 35.1557 36.5962 35.8755 35.8774C36.5953 35.1586 36.9997 34.1837 36.9997 33.1671V3.88398C37.0064 3.37635 36.9122 2.87244 36.7223 2.4015C36.5325 1.93056 36.2508 1.50198 35.8937 1.14063C35.5367 0.779284 35.1113 0.492378 34.6422 0.296571C34.1732 0.100763 33.6699 -4.5113e-05 33.1615 1.5145e-08ZM3.83814 1.27762H33.1615C33.8401 1.27762 34.491 1.54684 34.9708 2.02604C35.4507 2.50524 35.7203 3.15518 35.7203 3.83287V7.66575H1.2282V3.83287C1.22814 3.49297 1.29598 3.15647 1.42776 2.84307C1.55953 2.52968 1.7526 2.24569 1.99565 2.00773C2.2387 1.76977 2.52685 1.58263 2.84322 1.45726C3.1596 1.3319 3.49784 1.27083 3.83814 1.27762ZM33.1615 35.7735H3.83814C3.15951 35.7735 2.50868 35.5043 2.02882 35.0251C1.54896 34.5459 1.27938 33.8959 1.27938 33.2182V8.94337H35.8226V33.2182C35.8229 33.5626 35.7535 33.9034 35.6185 34.2203C35.4836 34.5372 35.2859 34.8235 35.0374 35.0622C34.7888 35.3008 34.4945 35.4869 34.1722 35.6091C33.8498 35.7313 33.5061 35.7873 33.1615 35.7735Z" />
        <path d="M31.2935 5.74958C32.0001 5.74958 32.5729 5.17757 32.5729 4.47196C32.5729 3.76635 32.0001 3.19434 31.2935 3.19434C30.587 3.19434 30.0142 3.76635 30.0142 4.47196C30.0142 5.17757 30.587 5.74958 31.2935 5.74958Z" />
        <path d="M26.1759 5.74958C26.8824 5.74958 27.4552 5.17757 27.4552 4.47196C27.4552 3.76635 26.8824 3.19434 26.1759 3.19434C25.4693 3.19434 24.8965 3.76635 24.8965 4.47196C24.8965 5.17757 25.4693 5.74958 26.1759 5.74958Z" />
        <path d="M21.0587 5.74958C21.7653 5.74958 22.3381 5.17757 22.3381 4.47196C22.3381 3.76635 21.7653 3.19434 21.0587 3.19434C20.3521 3.19434 19.7793 3.76635 19.7793 4.47196C19.7793 5.17757 20.3521 5.74958 21.0587 5.74958Z" />
        <path d="M21.0585 16.6089H15.941C15.7713 16.6089 15.6086 16.6762 15.4886 16.796C15.3687 16.9158 15.3013 17.0783 15.3013 17.2477V26.1911C15.3013 26.3605 15.3687 26.523 15.4886 26.6428C15.6086 26.7626 15.7713 26.8299 15.941 26.8299H21.0585C21.2281 26.8299 21.3908 26.7626 21.5108 26.6428C21.6308 26.523 21.6982 26.3605 21.6982 26.1911V17.2477C21.6982 17.0783 21.6308 16.9158 21.5108 16.796C21.3908 16.6762 21.2281 16.6089 21.0585 16.6089ZM20.4188 25.5523H16.619V17.8865H20.3804L20.4188 25.5523Z" />
        <path d="M12.5257 16.6089C12.356 16.6089 12.1933 16.6762 12.0733 16.796C11.9534 16.9158 11.886 17.0783 11.886 17.2477V21.4894H8.12459V17.2477C8.12459 17.0783 8.0572 16.9158 7.93723 16.796C7.81727 16.6762 7.65456 16.6089 7.4849 16.6089C7.31525 16.6089 7.15254 16.6762 7.03258 16.796C6.91261 16.9158 6.84521 17.0783 6.84521 17.2477V22.1282C6.84849 22.2966 6.91694 22.4572 7.0362 22.5763C7.15546 22.6954 7.31627 22.7638 7.4849 22.767H11.886V26.1911C11.886 26.3605 11.9534 26.523 12.0733 26.6428C12.1933 26.7626 12.356 26.8299 12.5257 26.8299C12.6953 26.8299 12.858 26.7626 12.978 26.6428C13.098 26.523 13.1654 26.3605 13.1654 26.1911V17.2477C13.1654 17.0783 13.098 16.9158 12.978 16.796C12.858 16.6762 12.6953 16.6089 12.5257 16.6089Z" />
        <path d="M29.5149 16.6089C29.3453 16.6089 29.1826 16.6762 29.0626 16.796C28.9426 16.9158 28.8752 17.0783 28.8752 17.2477V21.4894H25.1139V17.2477C25.1139 17.0783 25.0465 16.9158 24.9265 16.796C24.8065 16.6762 24.6438 16.6089 24.4742 16.6089C24.3045 16.6089 24.1418 16.6762 24.0218 16.796C23.9019 16.9158 23.8345 17.0783 23.8345 17.2477V22.1282C23.8377 22.2966 23.9062 22.4572 24.0255 22.5763C24.1447 22.6954 24.3055 22.7638 24.4742 22.767H28.8752V26.1911C28.8752 26.3605 28.9426 26.523 29.0626 26.6428C29.1826 26.7626 29.3453 26.8299 29.5149 26.8299C29.6846 26.8299 29.8473 26.7626 29.9672 26.6428C30.0872 26.523 30.1546 26.3605 30.1546 26.1911V17.2477C30.1546 17.0783 30.0872 16.9158 29.9672 16.796C29.8473 16.6762 29.6846 16.6089 29.5149 16.6089Z" />
    </svg>
</template>
<script>
    export default {
        name: 'NotFoundIcon',
        props: {
            width: {
                type: [Number, String],
                default: 37
            },
            height: {
                type: [Number, String],
                default: 37
            }
        }
    }
</script>
