import { useToast } from "vue-toastification";

const toast = useToast();

export default {
  validationErrorNotification(errors) {
    console.log(errors);
    // if (Object.keys(errors).length) {
    //   Object.keys(errors).forEach((error) => {
    //     toast.error(errors[error]);
    //   });
    // }
  },
  requestErrorNotification(error) {
    if (
      error.response?.data &&
      error.response.data.meta?.code == 422 &&
      Object.keys(error.response.data.meta?.error_description).length
    ) {
      Object.keys(error.response.data.meta.error_description).forEach((key) => {
        error.response.data.meta.error_description[key].forEach(
          (item, index) => {
            toast.error(error.response.data.meta.error_description[key][index]);
          }
        );
      });
    } else if (error.response?.data.meta?.error_description) {
      toast.error(error.response.data.meta.error_description);
    } else if (error.response?.data.data && error.response.data.data.messages) {
      toast.error(error.response.data.data.messages);
    } else {
      toast.error(error?.response?.data?.meta?.error_message ?? error.message);
    }
  },
  successNotification(message) {
    toast.success(message);
  },
  infoNotification(message, options = {}) {
    toast.info(message, options);
  },
  errorNotification(message) {
    toast.error(message);
  },
  warningNotification(message, options = {}) {
    toast.warning(message, options);
  },
};
