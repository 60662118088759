<template>
    <svg
        viewBox="0 0 12 12"
        :width="size"
        :height="size"
        :style="{ transform: `rotate(${transform}deg)` }"
        class="arrow-icon"
    >
        <path d="M10.9744 0.479032L5.1105 0.425228C4.6703 0.420009 4.32852 0.761781 4.33374 1.20198C4.33896 1.64219 4.68894 1.99216 5.12914 1.99738L9.09096 2.04435L0.903953 10.2314C0.608785 10.5265 0.614751 11.0296 0.917001 11.3319C1.21925 11.6341 1.72234 11.6401 2.01751 11.3449L10.2511 3.1113L10.2988 7.13601C10.3041 7.57621 10.654 7.92619 11.0942 7.9314C11.3143 7.93401 11.5019 7.84192 11.6417 7.70211C11.7815 7.56229 11.8736 7.37475 11.871 7.15465L11.7698 1.27443C11.7491 0.849761 11.3991 0.499787 10.9744 0.479032Z" />
    </svg>
</template>

<script>
    export default {
        name: 'ArrowIcon',
        props: {
            size: {
                type: [Number, String],
                default: 12
            },
            transform: {
                type: [Number, String],
                default: 0
            }
        }
    }
</script>
