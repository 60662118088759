export const GET_ALL_USERS_BY_SUPERADMIN = 'GET_ALL_USERS_BY_SUPERADMIN';
export const GET_ALL_BUSINESS = 'GET_ALL_BUSINESS';
export const GET_ALL_BUSINESS_SUBUSERS = 'GET_ALL_BUSINESS_SUBUSERS';
export const CREATE_USER_BY_SUPERADMIN = 'CREATE_USER_BY_SUPERADMIN';
export const CREATE_BUSINESS_BY_SUPERADMIN = 'CREATE_BUSINESS_BY_SUPERADMIN';
export const WIPE_OUT_CARD = 'WIPE_OUT_CARD';
export const SET_SELECTED_USER = 'SET_SELECTED_USER';
export const TOGGLE_USER_IS_PRO = 'TOGGLE_USER_IS_PRO';
export const DEACTIVATE_USER = 'DEACTIVATE_USER';
export const CONVERT_USER_TO_BUSINESS = 'CONVERT_USER_TO_BUSINESS';
export const CONVERT_BUSINESS_TO_USER = 'CONVERT_BUSINESS_TO_USER';
export const REMOVE_USER_FROM_BUSINESS = 'REMOVE_USER_FROM_BUSINESS';
export const BIND_USER_TO_EXISTING_BUSINESS = 'BIND_USER_TO_EXISTING_BUSINESS';
