<template>
    <div
        class="manufacturer-items__item"
        :style="data?.cover && {
            backgroundImage: `url(${data?.cover})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover'
            }"
    >
        <div class="manufacturer-items__item-info">
            <p>{{ data?.name }}</p>
            <p>{{ data?.model }}</p>
            <p>{{ data?.serial_number }}</p>
        </div>
        <div class="manufacturer-items__item-buttons">
            <button @click.stop="btnHandler(btn)"
                    class="primary-button"
                    v-if="data"
                    v-for="btn in data">
                <div v-if="btn?.type === TYPE_FIELDS.phone" class="phone-icon-container">
                    <phone-icon/>
                </div>
                {{ btn.name }}
            </button>
        </div>
        <div class="manufacturer-items__item-actions">
            <button class="icon-button edit-btn" @click.stop="$emit('onEdit')">
                <settings-icon/>
            </button>
            <button class="icon-button edit-btn" @click.stop="$emit('onRemove')">
                <trash-icon/>
            </button>
        </div>
    </div>
</template>

<script>
import PhoneIcon from "../icons/PhoneIcon";
import {TYPE_FIELDS} from "../constants";
import {SettingsIcon, TrashIcon} from "../icons";

export default {
    name: "ManufacturerItemCard",
    components: {
        PhoneIcon,
        SettingsIcon,
        TrashIcon
    },
    data() {
        return {
            TYPE_FIELDS,
        }
    },
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    methods: {
        btnHandler(item) {
            if (item.type === TYPE_FIELDS.link) {
                window.open(item.data?.link, '_blank')
            } else if (item.type === TYPE_FIELDS.phone) {
                window.open(`tel:${item.data?.phone_number}`, '_parent')
            }
        }
    }
}
</script>

