<template>
    <svg
        viewBox="0 0 13 12"
        :width="width"
        :height="height"
        class="signout-icon"
    >
        <path d="M9.6291 3.50971C9.3794 3.73887 9.3638 4.12082 9.59789 4.38054L10.7059 5.55693H7.80317V6.77915H10.7059L9.59789 7.95554C9.3638 8.19998 9.3794 8.58193 9.6291 8.82637C9.75395 8.93332 9.89441 8.99443 10.0505 8.99443C10.2221 8.99443 10.3782 8.93332 10.5031 8.79582L13.0001 6.15276L10.5031 3.54026C10.269 3.29582 9.8788 3.28054 9.6291 3.50971Z" />
        <path d="M3.90156 6.15278C3.90156 5.81667 4.18247 5.54167 4.52581 5.54167H7.80312V1.76806C7.80312 1.06528 7.22569 0.5 6.5078 0.5H1.29532C0.577431 0.5 0 1.06528 0 1.76806V10.2319C0 10.9347 0.577431 11.5 1.29532 11.5H6.5078C7.22569 11.5 7.80312 10.9347 7.80312 10.2319V6.76389H4.52581C4.18247 6.76389 3.90156 6.48889 3.90156 6.15278Z" />
    </svg>
</template>

<script>
    export default {
        name: 'SignoutIcon',
        props: {
            width: {
                type: [Number, String],
                default: 13
            },
            height: {
                type: [Number, String],
                default: 12
            }
        }
    }
</script>
