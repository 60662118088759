<template>
    <svg
        viewBox="0 0 18 18"
        :width="size"
        :height="size"
        class="image-icon"
    >
        <path d="M1.92857 0C0.871262 0 0 0.871264 0 1.92857V16.0714C0 17.1287 0.871262 18 1.92857 18H16.0714C17.1287 18 18 17.1287 18 16.0714V1.92857C18 0.871264 17.1287 0 16.0714 0H1.92857ZM1.92857 1.28571H16.0714C16.4387 1.28571 16.7143 1.56131 16.7143 1.92857V9.92411L14.2534 7.06515C13.4022 6.13014 12.1942 6.10035 11.4471 7.03502L7.21708 11.7334L5.7154 9.9216C4.95501 8.98018 3.71071 9.05046 2.97824 9.91407L1.28571 11.9343V1.92857C1.28571 1.56131 1.56131 1.28571 1.92857 1.28571ZM13.279 7.90388L16.7143 11.8941V16.0714C16.7143 16.4387 16.4387 16.7143 16.0714 16.7143H11.3417L8.04576 12.7354L12.4026 7.89509C12.6839 7.59748 13.0477 7.64462 13.279 7.90388V7.90388ZM9.67174 16.7143H1.92857C1.56131 16.7143 1.28571 16.4387 1.28571 16.0714V13.9344L3.96261 10.7402C4.18445 10.4817 4.52957 10.5092 4.72475 10.7427C6.37374 12.7333 8.02274 14.7238 9.67174 16.7143Z" />
        <path d="M5.14272 2.57132C3.73017 2.57132 2.57129 3.7302 2.57129 5.14275C2.57129 6.55529 3.73017 7.71418 5.14272 7.71418C6.55526 7.71418 7.71415 6.55529 7.71415 5.14275C7.71415 3.7302 6.55526 2.57132 5.14272 2.57132ZM5.14272 3.85703C5.86041 3.85703 6.42843 4.42505 6.42843 5.14275C6.42843 5.86045 5.86041 6.42846 5.14272 6.42846C4.42502 6.42846 3.857 5.86045 3.857 5.14275C3.857 4.42505 4.42502 3.85703 5.14272 3.85703Z" />
    </svg>
</template>

<script>
    export default {
        name: 'ImageIcon',
        props: {
            size: {
                type: [Number, String],
                default: 18
            }
        }
    }
</script>
