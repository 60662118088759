<template>
    <modal @close="$emit('onClose')">
        <template #body>
            <Form
                class="h-100 column-start"
                :validation-schema="schema"
                :initial-values="formValues"
                @submit="submitHandler"
                @invalid-submit="onInvalidSubmit"
                v-slot="{ errors }"
            >
                <div class="column-start w-100 g-2">
                    <div class="row-start">
                        <div class="lg-6 sm-12">
                            <form-input
                                id="name"
                                class="w-100 pr-4 pr-sm-0 mb-sm-1"
                                label="Name"
                                name="name"
                                placeholder="Name"
                                :errors="errors"
                            />
                        </div>
                        <div class="lg-6 sm-12">
                            <form-input
                                id="model"
                                class="w-100 pr-sm-0 mb-sm-1"
                                label="Model"
                                name="model"
                                placeholder="Model"
                                :errors="errors"
                            />
                        </div>
                    </div>
                    <div class="lg-12">
                        <form-input
                            id="serial_number"
                            class="w-100 pr-sm-0 mb-sm-1"
                            label="Serial number"
                            name="serial_number"
                            placeholder="Serial number"
                            :errors="errors"
                        />
                    </div>
                    <div class="lg-12 add-button-container mt-1 column-start g-2">
                        <button
                            type="submit"
                            class="primary-button w-100 mt-5"
                        >Save
                        </button>
                    </div>
                </div>
            </Form>
        </template>
    </modal>
</template>

<script>
import Modal from "./Modal";
import {Form} from 'vee-validate';
import * as yup from "yup";
import {CloseIcon, SettingsIcon} from "../../icons";
import TrashIcon from "../../icons/TrashIcon";
import {mapActions, mapGetters} from "vuex";
import {TYPE_FIELDS} from "../../constants";


export default {
    name: "CreateManufacturerModal",
    components: {
        TrashIcon,
        Modal,
        Form,
        CloseIcon,
        SettingsIcon
    },
    data() {
        return {
            formValues: {
                name: '',
                serial_number: '',
                model: '',
            },
            TYPE_FIELDS,
        }
    },
    props: {
        editData: {
            type: Object,
            default: null
        }
    },
    computed: {
        ...mapGetters({
            uniqueUrlCode: 'user/getUniqueUrl',
            getUserId: 'user/getUserId',
        }),
        schema() {
            let baseRules = {
                name: yup.string().required().label('Name'),
                serial_number: yup.string().required().label('Serial number'),
                model: yup.string().required().label('Model'),
            }
            return yup.object(baseRules);
        },
    },
    methods: {
        submitHandler(values) {
            this.$emit('submit', {user_id: this.$route.params.id, ...values});
        },
        onInvalidSubmit({values, errors, results}) {
            /* values -> current form values
             * errors -> a map of field names and their first error message
             * results ->  a detailed map of field names and their validation results
             */
            this.$store.dispatch('notifications/SHOW_VALIDATION_ERROR_NOTIFICATION', errors);
        },
        initData(data) {
            this.formValues.model = data?.model;
            this.formValues.name = data?.name;
            this.formValues.serial_number = data?.serial_number;
        }
    },
    created() {
        if (this.editData) this.initData(this.editData);
    }
}
</script>


