<template>
    <div class="public-profile-layout">
        <div class="public-profile-layout-container">
            <slot></slot>
        </div>
    </div>
</template>

<script>

export default {
    name: "PublicProfileLayout"
}
</script>
