<template>
    <svg
        viewBox="0 0 21 16"
        :width="width"
        :height="height"
        class="upload-icon"
    >
        <path d="M21 11.0215C21 13.5966 18.865 15.7253 16.205 15.7253C15.715 15.7253 15.33 15.3476 15.33 14.867C15.33 14.3863 15.715 14.0086 16.205 14.0086C17.885 14.0086 19.25 12.6695 19.25 11.0215C19.25 9.64807 18.305 8.44635 16.905 8.13734C16.66 8.06867 16.485 7.93133 16.345 7.75966C16.24 7.55365 16.205 7.3133 16.24 7.1073C16.345 6.72961 16.38 6.38627 16.38 6.04292C16.38 3.67382 14.42 1.78541 12.04 1.78541C10.08 1.78541 8.365 3.05579 7.84 4.90987C7.735 5.32189 7.28 5.59657 6.86 5.5279C6.58 5.49356 6.335 5.45923 6.09 5.45923C3.71 5.39056 1.75 7.27897 1.75 9.64807C1.75 12.0172 3.71 13.9056 6.09 13.9056C6.58 13.9056 6.965 14.2833 6.965 14.7639C6.965 15.2446 6.58 15.6223 6.09 15.6223C2.73 15.6223 0 12.9442 0 9.64807C0 6.35193 2.73 3.67382 6.09 3.67382C6.195 3.67382 6.3 3.67382 6.405 3.67382C7.35 1.47639 9.555 0 12.04 0C15.4 0 18.13 2.67811 18.13 5.97425C18.13 6.21459 18.13 6.45494 18.095 6.69528C19.845 7.45064 21 9.09871 21 11.0215ZM10.99 16C11.48 16 11.865 15.6223 11.865 15.1416V10.7124L12.88 11.7768C13.055 11.9485 13.265 12.0515 13.51 12.0515C13.72 12.0515 13.93 11.9828 14.105 11.8112C14.455 11.4678 14.455 10.9528 14.14 10.6094L11.62 8C11.445 7.82833 11.235 7.72532 10.99 7.72532C10.745 7.72532 10.535 7.82833 10.36 8L7.805 10.5751C7.455 10.9185 7.49 11.4678 7.84 11.7768C8.19 12.1202 8.75 12.0858 9.065 11.7425L10.115 10.6781V15.1416C10.115 15.6223 10.5 16 10.99 16Z" />
    </svg>
</template>
<script>
export default {
    name: 'UploadIcon',
    props: {
        width: {
            type: [Number, String],
            default: 21
        },
        height: {
            type: [Number, String],
            default: 16
        }
    }
}
</script>
