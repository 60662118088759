<template>
    <svg
        viewBox="0 0 13 13"
        :width="size"
        :height="size"
        class="window-icon"
    >
        <path d="M4.13636 7.09091H1.77273C0.793677 7.09091 0 7.88459 0 8.86364V11.2273C0 12.2063 0.793677 13 1.77273 13H4.13636C5.11541 13 5.90909 12.2063 5.90909 11.2273V8.86364C5.90909 7.88459 5.11541 7.09091 4.13636 7.09091Z"/>
        <path d="M11.2273 7.09091H8.86369C7.88464 7.09091 7.09097 7.88459 7.09097 8.86364V11.2273C7.09097 12.2063 7.88464 13 8.86369 13H11.2273C12.2064 13 13.0001 12.2063 13.0001 11.2273V8.86364C13.0001 7.88459 12.2064 7.09091 11.2273 7.09091Z"/>
        <path d="M11.2273 0H8.86369C7.88464 0 7.09097 0.793677 7.09097 1.77273V4.13636C7.09097 5.11541 7.88464 5.90909 8.86369 5.90909H11.2273C12.2064 5.90909 13.0001 5.11541 13.0001 4.13636V1.77273C13.0001 0.793677 12.2064 0 11.2273 0Z"/>
        <path d="M4.13636 0H1.77273C0.793677 0 0 0.793677 0 1.77273V4.13636C0 5.11541 0.793677 5.90909 1.77273 5.90909H4.13636C5.11541 5.90909 5.90909 5.11541 5.90909 4.13636V1.77273C5.90909 0.793677 5.11541 0 4.13636 0Z"/>
    </svg>
</template>

<script>
    export default {
        name: 'WindowIcon',
        props: {
            size: {
                type: [Number, String],
                default: 13
            }
        }
    }
</script>
