<template>
    <svg
        viewBox="0 0 9 9"
        :width="size"
        :height="size"
        class="linkedin-icon"
    >
        <path d="M-1.52588e-05 1.04545C-1.52588e-05 0.742418 0.101339 0.492418 0.304039 0.295455C0.506739 0.0984818 0.770258 0 1.09458 0C1.41312 0 1.67083 0.0969636 1.86775 0.290909C2.07045 0.490909 2.1718 0.751509 2.1718 1.07273C2.1718 1.36364 2.07335 1.60605 1.87643 1.8C1.67373 2 1.40732 2.1 1.0772 2.1H1.06852C0.749982 2.1 0.492266 2 0.295352 1.8C0.0984374 1.6 -1.52588e-05 1.34848 -1.52588e-05 1.04545ZM0.112919 9V2.92727H2.04149V9H0.112919ZM3.11002 9H5.03859V5.60909C5.03859 5.39696 5.06176 5.23333 5.10809 5.11818C5.18917 4.91212 5.31224 4.73787 5.4773 4.59545C5.64236 4.45303 5.8494 4.38182 6.09844 4.38182C6.74709 4.38182 7.07141 4.83939 7.07141 5.75455V9H8.99998V5.51818C8.99998 4.62121 8.79729 3.94091 8.39188 3.47727C7.98647 3.01364 7.45076 2.78182 6.78473 2.78182C6.03763 2.78182 5.45558 3.11818 5.03859 3.79091V3.80909H5.02991L5.03859 3.79091V2.92727H3.11002C3.1216 3.12121 3.1274 3.72424 3.1274 4.73636C3.1274 5.74848 3.1216 7.16969 3.11002 9Z" />
    </svg>
</template>

<script>
    export default {
        name: 'LinkedinIcon',
        props: {
            size: {
                type: [Number, String],
                default: 10
            }
        }
    }
</script>
