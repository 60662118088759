<template>
    <svg
        viewBox="0 0 15 16"
        :width="width"
        :height="height"
        class="star-icon"
    >
        <path d="M3.24221 15.4681C3.18277 15.4945 3.11783 15.5043 3.05371 15.4966C2.98958 15.489 2.92844 15.464 2.8762 15.4243C2.82397 15.3845 2.78242 15.3313 2.75558 15.2697C2.72873 15.2081 2.71751 15.1404 2.723 15.0729L3.12308 10.2413L0.0917563 6.57974C0.0493822 6.52856 0.0203538 6.46676 0.00749258 6.40034C-0.00536866 6.33391 -0.00162525 6.26513 0.0183591 6.20067C0.0383434 6.13621 0.0738888 6.07827 0.12154 6.03248C0.169192 5.98669 0.227328 5.95461 0.290298 5.93936L4.79458 4.84481L7.17934 0.689768C7.21268 0.631859 7.25972 0.583962 7.3159 0.550716C7.37208 0.517469 7.43549 0.5 7.5 0.5C7.5645 0.5 7.62792 0.517469 7.6841 0.550716C7.74027 0.583962 7.78732 0.631859 7.82066 0.689768L10.2054 4.84481L14.7097 5.93936C14.7727 5.95461 14.8308 5.98669 14.8785 6.03248C14.9261 6.07827 14.9617 6.13621 14.9816 6.20067C15.0016 6.26513 15.0054 6.33391 14.9925 6.40034C14.9796 6.46676 14.9506 6.52856 14.9082 6.57974L11.8769 10.2413L12.277 15.0729C12.2826 15.1404 12.2715 15.2083 12.2447 15.27C12.2179 15.3316 12.1764 15.385 12.1241 15.4249C12.0719 15.4647 12.0107 15.4897 11.9465 15.4974C11.8823 15.5052 11.8173 15.4953 11.7578 15.4689L7.5 13.5768L3.24221 15.4681Z" />
    </svg>
</template>

<script>
    export default {
        name: 'StarIcon',
        props: {
            width: {
                type: [Number, String],
                default: 15
            },
            height: {
                type: [Number, String],
                default: 16
            }
        }
    }
</script>
