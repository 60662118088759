<template>
    <svg
        viewBox="0 0 15 16"
        :width="size"
        :height="size"
        class="calendar-icon"
    >
        <path d="M12.25 2.06452H11.25V0H9.75V2.06452H5.25V0H3.75V2.06452H2.75C2.02065 2.06452 1.32118 2.36359 0.805456 2.89595C0.289731 3.42832 0 4.15035 0 4.90323V13.1613C0 13.9142 0.289731 14.6362 0.805456 15.1686C1.32118 15.7009 2.02065 16 2.75 16H12.25C12.6111 16 12.9687 15.9266 13.3024 15.7839C13.636 15.6413 13.9392 15.4322 14.1945 15.1686C14.4499 14.905 14.6525 14.592 14.7907 14.2476C14.9289 13.9032 15 13.5341 15 13.1613V4.90323C15 4.53044 14.9289 4.16131 14.7907 3.8169C14.6525 3.47249 14.4499 3.15955 14.1945 2.89595C13.9392 2.63236 13.636 2.42326 13.3024 2.2806C12.9687 2.13794 12.6111 2.06452 12.25 2.06452ZM2.75 3.6129H3.75V5.67742H5.25V3.6129H9.75V5.67742H11.25V3.6129H12.25C12.5815 3.6129 12.8995 3.74885 13.1339 3.99083C13.3683 4.23281 13.5 4.56101 13.5 4.90323V10.5806H1.5V4.90323C1.5 4.56101 1.6317 4.23281 1.86612 3.99083C2.10054 3.74885 2.41848 3.6129 2.75 3.6129ZM12.25 14.4516H2.75C2.41848 14.4516 2.10054 14.3157 1.86612 14.0737C1.6317 13.8317 1.5 13.5035 1.5 13.1613V12.129H13.5V13.1613C13.5 13.5035 13.3683 13.8317 13.1339 14.0737C12.8995 14.3157 12.5815 14.4516 12.25 14.4516Z"/>
    </svg>
</template>

<script>
    export default {
        name: 'CalendarIcon',
        props: {
            size: {
                type: [Number, String],
                default: 16
            }
        }
    }
</script>
