<template>
    <svg
        :width="width"
        :height="height"
        class="zelle-colored-icon"
        viewBox="6 6 36 36"
    >
        <path
            fill="#a0f"
            d="M35,42H13c-3.866,0-7-3.134-7-7V13c0-3.866,3.134-7,7-7h22c3.866,0,7,3.134,7,7v22 C42,38.866,38.866,42,35,42z"
        />
        <path
            fill="#fff"
            d="M17.5,18.5h14c0.552,0,1-0.448,1-1V15c0-0.552-0.448-1-1-1h-14c-0.552,0-1,0.448-1,1v2.5 C16.5,18.052,16.948,18.5,17.5,18.5z"
        />
        <path
            fill="#fff"
            d="M17,34.5h14.5c0.552,0,1-0.448,1-1V31c0-0.552-0.448-1-1-1H17c-0.552,0-1,0.448-1,1v2.5 C16,34.052,16.448,34.5,17,34.5z"
        />
        <path
            fill="#fff"
            d="M22.25,11v6c0,0.276,0.224,0.5,0.5,0.5h3.5c0.276,0,0.5-0.224,0.5-0.5v-6c0-0.276-0.224-0.5-0.5-0.5 h-3.5C22.474,10.5,22.25,10.724,22.25,11z"
        />
        <path
            fill="#fff"
            d="M22.25,32v6c0,0.276,0.224,0.5,0.5,0.5h3.5c0.276,0,0.5-0.224,0.5-0.5v-6c0-0.276-0.224-0.5-0.5-0.5 h-3.5C22.474,31.5,22.25,31.724,22.25,32z"
        />
        <path
            fill="#fff"
            d="M16.578,30.938H22l10.294-12.839c0.178-0.222,0.019-0.552-0.266-0.552H26.5L16.275,30.298 C16.065,30.553,16.247,30.938,16.578,30.938z"
        />
    </svg>
</template>
<script>
export default {
    name: "ZelleColoredIcon",
    props: {
        width: {
            type: [Number, String],
            default: 32,
        },
        height: {
            type: [Number, String],
            default: 32,
        },
    },
};
</script>

<style scoped>
.cls-1 {
    fill: #5f469c;
}
</style>
