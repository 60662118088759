<template>
    <svg
        viewBox="0 0 508.33 508.36"
        :width="size"
        :height="size"
        class="paypal-colored-icon"
    >
        <path
            class="cls-1"
            d="M485,29.72c11.45,15.13,16.61,40.21,19.15,70.7,3.36,45.5,4.71,100.1,6,156.52-1.67,57.47-2.35,115.43-6,156.52-2.88,31.54-9,52.66-19.22,65.47-13,12.79-37.94,23.59-73.5,26.15-43.25,3.69-96.61,3.65-155.48,5.1-65.44-1.12-109.82-.64-156.38-5.08-36.32-2.52-60.08-13.19-74.7-26.07-10.83-14.54-14-30-17-66.24-3.75-41.85-4.58-98.56-6-155.54C4,200.57,4.13,143.44,7.9,100.74,10.43,67.05,14.42,44.4,24.65,30,39,17.77,63.48,11.69,100,8.69c50-5.85,102.06-7,155.88-6.87,55.39.09,108.56,1.67,156,6.34,32,2.56,58.48,8.07,73.07,21.56Z"
            transform="translate(-1.83 -1.82)"
        />
        <path
            class="cls-2"
            d="M392.67,237.3c-11.16,50.68-52.9,80.5-102.83,80.5H237.67L219.79,399h-73l6.9-30.68h43.82l4.18-18.95,13.7-62.25h32.75c63.47,0,113.09-39,126.41-99.44A125.58,125.58,0,0,0,376.87,173C391.15,186.69,399.18,207.87,392.67,237.3ZM159.09,344.16H105L160.19,99H284.64c23,0,48.44,10.42,60.86,32.5a61.42,61.42,0,0,1,7.6,27,88.16,88.16,0,0,1-2.16,23.92c-4.36,19.8-13.43,36.34-25.79,49.23-18.88,19.72-45.52,30.74-75.19,31.18-.62,0-1.22.08-1.84.08H195.95l-17.87,81.2ZM206.38,216h34.27a41.43,41.43,0,0,0,21.83-6.68,48.83,48.83,0,0,0,7.18-5.54,43.58,43.58,0,0,0,12.74-21.31c3.41-12.19-.54-22.77-8.7-28.62A27,27,0,0,0,257.8,149H222l-1.15,4.9Z"
            transform="translate(-1.83 -1.82)"
        />
    </svg>
</template>
<script>
export default {
    name: "PaypalColoredIcon",
    props: {
        size: {
            type: [Number, String],
            default: 32,
        },
    },
};
</script>

<style scoped>
.cls-1 {
    fill: #0070ba;
    fill-rule: evenodd;
}
.cls-2 {
    fill: #fff;
}
</style>
