<template>
    <svg
        viewBox="0 0 12 13"
        :width="width"
        :height="height"
        class="trash-icon"
    >
        <path d="M11.4857 1.90861H8.57143V1.28149C8.57143 0.840814 8.16 0.501831 7.64571 0.501831H4.35429C3.84 0.501831 3.42857 0.840814 3.42857 1.28149V1.90861H0.514286C0.222857 1.90861 0 2.12895 0 2.41709C0 2.70522 0.222857 2.92556 0.514286 2.92556H1.14857L1.78286 10.8239C1.8 11.7561 2.55429 12.5018 3.49714 12.5018H8.48571C9.42857 12.5018 10.1829 11.7561 10.2 10.8239L10.8514 2.94251H11.4857C11.7771 2.94251 12 2.72217 12 2.43403C12 2.1459 11.7771 1.90861 11.4857 1.90861ZM4.45714 1.51878H7.54286V1.90861H4.45714V1.51878ZM4.66286 9.77302H4.64571C4.37143 9.77302 4.14857 9.55268 4.13143 9.28149L3.94286 5.1459C3.92571 4.85776 4.14857 4.62047 4.44 4.62047H4.45714C4.73143 4.62047 4.95429 4.84081 4.97143 5.112L5.16 9.24759C5.16 9.51878 4.95429 9.75607 4.66286 9.77302ZM7.86857 9.28149C7.85143 9.55268 7.62857 9.77302 7.35429 9.77302H7.33714C7.04571 9.75607 6.84 9.51878 6.84 9.24759L7.02857 5.112C7.04571 4.84081 7.26857 4.62047 7.54286 4.62047H7.56C7.85143 4.63742 8.05714 4.87471 8.05714 5.1459L7.86857 9.28149Z" />
    </svg>
</template>

<script>
    export default {
        name: 'TrashIcon',
        props: {
            width: {
                type: [Number, String],
                default: 12
            },
            height: {
                type: [Number, String],
                default: 13
            }
        }
    }
</script>
