<template>
    <svg
        viewBox="0 0 14 16"
        :width="width"
        :height="height"
        class="arrow-pointer-icon"
    >
        <path d="M4.24024 15.038C4.28682 15.0477 4.33032 15.05 4.37399 15.05C4.61641 15.05 4.84494 14.9291 4.97835 14.7226C4.97837 14.7225 4.97838 14.7225 4.9784 14.7225L6.62033 12.2024L8.07247 14.3108C8.07251 14.3108 8.07254 14.3108 8.07257 14.3109C8.18461 14.4748 8.36294 14.586 8.56226 14.6167L8.56275 14.6168C8.75946 14.6452 8.96399 14.5934 9.11932 14.4672C9.11939 14.4672 9.11947 14.4671 9.11954 14.467L10.9678 12.9973C11.2655 12.7622 11.3272 12.3353 11.1131 12.0246L11.113 12.0245L9.62803 9.88091L12.4772 9.2762L12.4774 9.27616C12.7624 9.21459 12.9838 8.98955 13.0363 8.7024C13.0933 8.41309 12.9694 8.12348 12.7221 7.96454C12.722 7.96451 12.722 7.96448 12.7219 7.96445L2.06741 1.066L2.06725 1.0659C1.82005 0.907121 1.50601 0.911879 1.2636 1.07774L1.2636 1.07774L1.26341 1.07787C1.02358 1.24359 0.904243 1.5353 0.96618 1.82049C0.966187 1.82052 0.966194 1.82055 0.966201 1.82058L3.66526 14.4784L3.66528 14.4785C3.72706 14.7652 3.95061 14.9834 4.24024 15.038ZM4.24024 15.038C4.24007 15.038 4.2399 15.0379 4.23972 15.0379L4.24999 14.989L4.24076 15.0381C4.24059 15.0381 4.24041 15.038 4.24024 15.038ZM6.58967 12.1578L6.61918 12.1125L6.64985 12.1571L6.58967 12.1578ZM8.12113 9.23356L8.04754 9.24913L8.09037 9.31095L10.2203 12.3851L8.71795 13.5809L6.64265 10.5676L6.60031 10.5061L6.55957 10.5687L4.47971 13.7615L2.00137 2.14487L11.7601 8.46391L8.12113 9.23356Z" stroke-width="0.1"/>
    </svg>
</template>

<script>
    export default {
        name: 'ArrowPointerIcon',
        props: {
            width: {
                type: [Number, String],
                default: 14
            },
            height: {
                type: [Number, String],
                default: 16
            }
        }
    }
</script>
