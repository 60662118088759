import * as type from '../actions/manufacturer'
import api from "../../api";
import NotificationsService from "../../NotificationsService";

export default {
    namespaced: true,
    state: {
        manufacturerItems: [],
        manufacturerAllItems: [],
        manufacturerItem: null,
        isLoading: false,
        isLoadingCreate: false
    },
    getters: {
        manufacturerItems: state => state.manufacturerItems,
        manufacturerAllItems: state => state.manufacturerAllItems,
        manufacturerItem: state => state.manufacturerItem,
        isLoading: state => state.isLoading,
        isLoadingCreate: state => state.isLoadingCreate,
    },
    mutations: {
        [type.SET_MANUFACTURER_ITEMS](state, payload) {
            state.manufacturerItems = payload;
        },
        [type.SET_MANUFACTURER](state, payload) {
            state.manufacturerItem = payload;
        },
        [type.SET_LOADING](state, payload) {
            state.isLoading = payload;
        },
        [type.SET_LOADING_CREATE](state, payload) {
            state.isLoadingCreate = payload;
        },
        [type.SET_MANUFACTURER_ALL_ITEMS](state, pd) {
            state.manufacturerAllItems = pd?.data;
        }
    },
    actions: {
        async getManufacturerAllItems({commit}) {
            try {
                commit(type.SET_LOADING, true)
                const params = {perPage: 2000}
                const res = await api.get(`/api/manufacturer-items`, {params});
                commit(type.SET_MANUFACTURER_ALL_ITEMS, res.data.data);
            } catch (e) {
                NotificationsService.requestErrorNotification(e);
            } finally {
                commit(type.SET_LOADING, false)
            }
        },
        async createManufacturer({commit}, data) {
            try {
                commit(type.SET_LOADING_CREATE, true)
                const res = await api.post('/api/manufacturer-items', data);
                NotificationsService.successNotification('Success')
                return res.data.data;
            } catch (e) {
                NotificationsService.requestErrorNotification(e);
            } finally {
                commit(type.SET_LOADING_CREATE, false)
            }
        },
        async updateManufacturer({commit}, {id, data}) {
            try {
                commit(type.SET_LOADING_CREATE, true)
                const res = await api.post(`/api/manufacturer-items/${id}?_method=PUT`, data);
                NotificationsService.successNotification('Success')
                return res.data.data;
            } catch (e) {
                NotificationsService.requestErrorNotification(e);
            } finally {
                commit(type.SET_LOADING_CREATE, false)
            }
        },
        async getManufacturerItems({commit}, id) {
            try {
                commit(type.SET_LOADING, true)
                const res = await api.get(`/api/manufacturer-items/get-by-user/${id}`);
                commit(type.SET_MANUFACTURER_ITEMS, res.data.data);
            } catch (e) {
                NotificationsService.requestErrorNotification(e);
            } finally {
                commit(type.SET_LOADING, false)
            }
        },
        async removeManufacturerItem({commit}, id) {
            try {
                commit(type.SET_LOADING, false)
                const res = await api.delete(`/api/manufacturer-items/${id}`);
                NotificationsService.successNotification('Success')
                return res.data.data;
            } catch (e) {
                NotificationsService.requestErrorNotification(e);
            } finally {
                commit(type.SET_LOADING, false)
            }
        },
        async getManufactureItemsByCode({commit}, code) {
            try {
                commit(type.SET_LOADING, true);
                const res = await api.get(`/api/manufacturer-items/get-by-code/${code}`);
                commit(type.SET_MANUFACTURER, res.data.data);
                return res.data.data;
            } catch (e) {
                NotificationsService.requestErrorNotification(e);
            } finally {
                commit(type.SET_LOADING, false)
            }
        },
        async addButtonToManufacturerItem({commit}, data) {
            try {
                commit(type.SET_LOADING_CREATE, true)
                const res = await api.post(`/api/manufacturer-item-buttons`, data);
                NotificationsService.successNotification('Success');
                return res.data.data;
            } catch (e) {
                NotificationsService.requestErrorNotification(e);
            } finally {
                commit(type.SET_LOADING_CREATE, false)
            }
        },
        async updateButtonManufacturerItem({commit}, {id, data}) {
            try {
                commit(type.SET_LOADING_CREATE, true)
                const res = await api.post(`/api/manufacturer-item-buttons/${id}?_method=PUT`, data);
                NotificationsService.successNotification('Success');
                return res.data.data;
            } catch (e) {
                NotificationsService.requestErrorNotification(e);
            } finally {
                commit(type.SET_LOADING_CREATE, false)
            }
        },
        async deleteButtonManufacturerItem({commit}, id) {
            try {
                commit(type.SET_LOADING_CREATE, true)
                const res = await api.delete(`/api/manufacturer-item-buttons/${id}`);
                NotificationsService.successNotification('Success');
                return res.data.data;
            } catch (e) {
                NotificationsService.requestErrorNotification(e);
            } finally {
                commit(type.SET_LOADING_CREATE, false)
            }
        },
        async addContentForButton({commit}, {id, data}) {
            try {
                commit(type.SET_LOADING_CREATE, true)
                const res = await api.post(`/api/manufacturer-item-buttons/${id}/contents`, data);
                NotificationsService.successNotification('Success');
                return res.data.data;
            } catch (e) {
                NotificationsService.requestErrorNotification(e);
            } finally {
                commit(type.SET_LOADING_CREATE, false)
            }
        },
        async getButtonContent({commit}, id) {
            try {
                commit(type.SET_LOADING_CREATE, true)
                const res = await api.get(`/api/manufacturer-item-buttons/${id}/contents`);
                return res.data.data;
            } catch (e) {
                NotificationsService.requestErrorNotification(e);
            } finally {
                commit(type.SET_LOADING_CREATE, false)
            }
        },
        async deleteButtonContent({commit}, {id, params}) {
            try {
                commit(type.SET_LOADING_CREATE, false)
                const res = await api.delete(`/api/manufacturer-item-buttons/contents/${id}`, {params});
                NotificationsService.successNotification('Success')
                return res.data.data;
            } catch (e) {
                NotificationsService.requestErrorNotification(e);
            } finally {
                commit(type.SET_LOADING_CREATE, false)
            }
        },
        async reorderSection({commit}, params) {
            try {
                commit(type.SET_LOADING_CREATE, false)
                const res = await api.post(`/api/sections/reorder`, params);
                NotificationsService.successNotification('Success')
                return res.data.data;
            } catch (e) {
                NotificationsService.requestErrorNotification(e);
            } finally {
                commit(type.SET_LOADING_CREATE, false)
            }
        }
    }
}
