<template>
    <svg
        viewBox="120 0 500 500"
        :width="size"
        :height="size"
        :style="{ transform: `rotate(${transform}deg)` }"
        class="arrow-icon"
    >
        <path d="m367.36 439.6c39.762-58.801 134.96-206.08 134.96-272.72 0-84.559-68.32-152.88-152.32-152.88s-152.88 68.32-152.88 152.88c0 66.641 95.762 213.92 135.52 272.72 8.3984 11.762 26.32 11.762 34.719 0.003906zm-88.48-272.72c0-39.199 31.922-71.121 71.121-71.121s71.121 31.922 71.121 71.121c0 39.199-31.922 70.562-71.121 70.562s-71.121-31.922-71.121-70.562z"/>
    </svg>
</template>

<script>
    export default {
        name: 'AddressIcon',
        props: {
            size: {
                type: [Number, String],
                default: 12
            },
            transform: {
                type: [Number, String],
                default: 0
            }
        }
    }
</script>
