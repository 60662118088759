export const AUTH_SIGNUP = 'AUTH_SIGNUP';
export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_REQUEST = 'AUTH_REQUEST';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_ERROR = 'AUTH_ERROR';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const CHECK_VERIFIED_USER = 'CHECK_VERIFIED_USER';
export const USER_EMAIL_UNVERIFIED = 'USER_EMAIL_UNVERIFIED';
export const RESEND_VERIFICATION_EMAIL = 'RESEND_VERIFICATION_EMAIL';
