<template>
    <svg
        viewBox="0 0 10 11"
        :width="size"
        :height="size"
        class="link-icon"
    >
        <path d="M8.798 1.06012L8.79798 1.06001C8.27725 0.64358 7.61445 0.447033 6.95088 0.512278C6.28732 0.577523 5.67549 0.899399 5.24584 1.40928L4.49818 2.30413C4.38972 2.43395 4.33727 2.60154 4.35237 2.77003C4.36747 2.93852 4.44888 3.09411 4.5787 3.20257C4.70852 3.31104 4.87611 3.36349 5.0446 3.34839C5.21309 3.33329 5.36868 3.25187 5.47714 3.12206L6.22483 2.22709C6.33403 2.09681 6.46823 1.98973 6.61951 1.91219C6.7708 1.83465 6.93609 1.78823 7.10562 1.77566C7.27515 1.76309 7.44548 1.78463 7.60654 1.83901C7.7676 1.89339 7.91613 1.9795 8.04334 2.09226C8.28828 2.31987 8.43601 2.63317 8.45579 2.96695C8.47558 3.30073 8.36589 3.62929 8.14956 3.88424L6.59508 5.74536L6.58077 5.76119C6.49774 5.8575 6.40043 5.94052 6.29225 6.00736C6.04219 6.16249 5.74602 6.22598 5.45435 6.18699C5.16267 6.14801 4.89358 6.00895 4.69304 5.79359C4.57764 5.67018 4.41799 5.5976 4.24914 5.59177C4.08028 5.58594 3.91601 5.64733 3.79237 5.76249C3.66873 5.87764 3.59583 6.03714 3.58966 6.20599C3.58349 6.37483 3.64456 6.53923 3.75947 6.66309C4.04792 6.97261 4.40892 7.20543 4.80987 7.34052C5.21082 7.47561 5.6391 7.50872 6.05605 7.43688C6.18646 7.41395 6.31493 7.38113 6.44035 7.33868C6.88338 7.1892 7.27636 6.91997 7.57575 6.56083L9.12636 4.70491C9.3469 4.43884 9.51265 4.13179 9.61406 3.80141C9.71547 3.47104 9.75055 3.12388 9.71728 2.77989C9.68687 2.44632 9.58984 2.12224 9.43194 1.82684C9.27403 1.53144 9.05847 1.27072 8.798 1.06012Z" />
        <path d="M4.20786 7.95158L3.53967 8.75135C3.32722 9.00956 3.02337 9.1759 2.69138 9.21576C2.35938 9.25561 2.0248 9.1659 1.75727 8.9653C1.62369 8.86017 1.51254 8.72933 1.43039 8.58051C1.34824 8.43168 1.29675 8.2679 1.27899 8.09884C1.26122 7.92978 1.27753 7.75888 1.32694 7.59623C1.37636 7.43358 1.45788 7.28249 1.56669 7.15188L3.14794 5.25921L3.15958 5.24621C3.24262 5.14993 3.33993 5.06694 3.44812 5.00015C3.67483 4.85875 3.94044 4.79265 4.20699 4.81127C4.47353 4.82989 4.72737 4.93229 4.93222 5.10383C4.98128 5.1448 5.02721 5.18938 5.06962 5.2372C5.13037 5.30592 5.20523 5.36072 5.28911 5.39785C5.37298 5.43497 5.46388 5.45355 5.5556 5.4523C5.64622 5.45102 5.7355 5.43019 5.81733 5.39123C5.89916 5.35228 5.97162 5.29611 6.02976 5.22658L6.03739 5.2174C6.13496 5.10178 6.18759 4.95486 6.18562 4.80359C6.18366 4.65231 6.12723 4.50682 6.02668 4.39377C5.78389 4.11884 5.48455 3.89962 5.14917 3.7511C4.8138 3.60258 4.45029 3.52828 4.08353 3.53329C3.71677 3.53829 3.35542 3.62248 3.02422 3.78009C2.69302 3.9377 2.39978 4.16501 2.16457 4.44646L0.587738 6.33396C0.162431 6.84747 -0.0454715 7.5068 0.00834972 8.1714C0.062171 8.83599 0.373459 9.45327 0.875853 9.89166C1.12942 10.1105 1.42432 10.2763 1.74309 10.3791C2.06186 10.4819 2.39802 10.5198 2.73168 10.4903C2.81121 10.4839 2.89014 10.4738 2.96845 10.46C3.57479 10.3538 4.12439 10.0375 4.52085 9.56664L5.18681 8.76951C5.29528 8.63969 5.34773 8.4721 5.33263 8.30361C5.31753 8.13512 5.23612 7.97952 5.1063 7.87106C4.97648 7.7626 4.80889 7.71014 4.6404 7.72524C4.47191 7.74034 4.31632 7.82176 4.20785 7.95158H4.20786Z" />
    </svg>
</template>

<script>
    export default {
        name: 'LinkIcon',
        props: {
            size: {
                type: [Number, String],
                default: 10
            }
        }
    }
</script>
