import * as type from '../actions/qrUsers';
import NotificationsService from "../../NotificationsService";
import api from "../../api";

export default {
    namespaced: true,
    state: {
        users: null,
        isLoading: false
    },
    getters: {
      users: state => state.users,
      isLoading: state => state.isLoading
    },
    mutations:{
        [type.SET_LOADING](state, pd){
            state.isLoading = pd
        },
        [type.SET_QR_USERS](state, pd){
            if (pd.current_page > 1){
                const data = state.users?.data || [];
                data.push(...pd.data);
                state.users = {
                    ...pd,
                    data
                }
                return;
            }
            state.users = pd;
        }
    },
    actions: {
        async getQrUsers({commit}, data){
            try{
                const params = {
                    perPage: 20,
                    page: 1,
                    ...data
                }
                commit(type.SET_LOADING, true)
                const res = await api.get(`/api/qr-admins`, {params});
                commit(type.SET_QR_USERS, res.data.data);
            }catch (e) {
                NotificationsService.requestErrorNotification(e);
            }finally {
                commit(type.SET_LOADING, false)
            }
        },
        async addQrUser({commit}, data){
            try{
                commit(type.SET_LOADING, true);
                const res = await api.post(`/api/qr-admins`, data);
                NotificationsService.successNotification('QR Business profile has been created successfully!')
                return res.data.data;
            }catch (e) {

            }finally {
                commit(type.SET_LOADING, false)
            }
        },
        async getAnalyticsQR({commit}, params) {
            try{
                commit(type.SET_LOADING, true);
                const res = await api.get(`/api/analytics/qr`, {params});
                return res.data.data;
            }catch (e) {
                NotificationsService.requestErrorNotification(e);
            }finally {
                commit(type.SET_LOADING, false);
            }
        }
    }
}
