<template>
    <svg
        viewBox="0 0 14 14"
        :width="size"
        :height="size"
        class="search-icon"
    >
        <path d="M5.71766 11.4271C6.99619 11.4282 8.23776 10.9983 9.24177 10.2067L13.031 13.9959L14 13.0269L10.2108 9.23765C11.008 8.22034 11.4367 6.9628 11.4269 5.67039C11.4172 4.37799 10.9695 3.12707 10.157 2.12191C9.34459 1.11676 8.21534 0.41676 6.95371 0.136224C5.69208 -0.144312 4.3726 0.0111923 3.21075 0.577342C2.04891 1.14349 1.11333 2.08684 0.556814 3.25333C0.000294511 4.41982 -0.144289 5.74054 0.146673 6.9998C0.437635 8.25907 1.14695 9.38249 2.15879 10.1866C3.17063 10.9907 4.42521 11.428 5.71766 11.4271ZM5.71766 1.37125C6.57648 1.37125 7.41602 1.62592 8.1301 2.10306C8.84419 2.5802 9.40075 3.25837 9.72941 4.05182C10.0581 4.84527 10.1441 5.71836 9.97651 6.56068C9.80896 7.403 9.3954 8.17672 8.78812 8.784C8.18084 9.39128 7.40712 9.80485 6.56479 9.97239C5.72247 10.1399 4.84938 10.054 4.05594 9.72529C3.26249 9.39664 2.58431 8.84007 2.10718 8.12599C1.63004 7.4119 1.37537 6.57237 1.37537 5.71354C1.37537 4.5619 1.83286 3.45742 2.6472 2.64308C3.46153 1.82874 4.56601 1.37125 5.71766 1.37125Z" />
    </svg>
</template>

<script>
    export default {
        name: 'SearchIcon',
        props: {
            size: {
                type: [Number, String],
                default: 14
            }
        }
    }
</script>
