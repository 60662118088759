<template>
    <svg
        viewBox="0 0 22 17"
        :width="width"
        :height="height"
        class="video-icon"
    >
        <path d="M9.29599 4.82602L9.29607 4.82602C9.41862 4.8165 9.54109 4.84545 9.64641 4.90883C9.64654 4.90891 9.64667 4.90899 9.6468 4.90907L15.0428 8.09764C15.043 8.09774 15.0432 8.09784 15.0434 8.09794C15.1332 8.14969 15.2079 8.2242 15.2599 8.31396C15.3119 8.4039 15.3393 8.50599 15.3393 8.60991C15.3393 8.71383 15.3119 8.8159 15.2599 8.90584C15.2079 8.99559 15.1333 9.07009 15.0434 9.12184C15.0432 9.12195 15.043 9.12205 15.0428 9.12216L9.6463 12.311C9.64625 12.3111 9.64619 12.3111 9.64613 12.3111C9.52417 12.3836 9.38019 12.4098 9.2405 12.3849M9.29599 4.82602L8.85143 11.7957L8.75144 11.7942C8.75144 11.7945 8.75143 11.7948 8.75143 11.795C8.74954 11.9353 8.79764 12.0717 8.88714 12.1798C8.97678 12.288 9.10207 12.3607 9.2405 12.3849M9.29599 4.82602C9.14746 4.83768 9.00882 4.90501 8.90784 5.01455C8.80689 5.12405 8.75103 5.26764 8.75143 5.41656V5.41643H8.85143L8.75143 5.41673L9.29599 4.82602ZM9.2405 12.3849C9.2404 12.3849 9.2403 12.3849 9.24019 12.3849L9.2578 12.2864L9.24062 12.3849C9.24058 12.3849 9.24054 12.3849 9.2405 12.3849ZM4.92571 0.900004C2.70415 0.900004 0.9 2.70416 0.9 4.92572V12.2864C0.9 14.508 2.70415 16.3121 4.92571 16.3121H17.6843C19.9058 16.3121 21.71 14.508 21.71 12.2864V4.92572C21.71 2.70416 19.9058 0.900004 17.6843 0.900004H4.92571ZM9.3038 4.92569L9.30381 4.92571L9.3038 4.92569ZM4.92571 2.08143H17.6843C19.2687 2.08143 20.5286 3.34135 20.5286 4.92572V12.2864C20.5286 13.8708 19.2687 15.1307 17.6843 15.1307H4.92571C3.34134 15.1307 2.08143 13.8708 2.08143 12.2864V4.92572C2.08143 3.34135 3.34134 2.08143 4.92571 2.08143ZM9.93286 10.7615V6.45804L13.5698 8.60622L9.93286 10.7615Z" stroke-width="0.2"/>
    </svg>
</template>

<script>
    export default {
        name: 'VideoIcon',
        props: {
            width: {
                type: [Number, String],
                default: 22
            },
            height: {
                type: [Number, String],
                default: 18
            }
        }
    }
</script>
