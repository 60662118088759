<template>
    <svg
        viewBox="0 0 13 13"
        :width="size"
        :height="size"
        class="dashboard-icon"
    >
        <path d="M6.07477 3.48862V0C4.82737 0.0830766 3.6303 0.523725 2.62674 1.26924C1.62319 2.01476 0.855626 3.03358 0.41587 4.20385C-0.0238869 5.37412 -0.117224 6.6463 0.147022 7.86822C0.411268 9.09015 1.02191 10.2101 1.90591 11.0941C2.78992 11.9781 3.90985 12.5887 5.13178 12.853C6.3537 13.1172 7.62588 13.0239 8.79615 12.5841C9.96642 12.1444 10.9852 11.3768 11.7308 10.3733C12.4763 9.3697 12.9169 8.17263 13 6.92523H9.51135C9.43116 7.48298 9.19729 8.0074 8.83595 8.43977C8.4746 8.87215 8.00003 9.19541 7.46537 9.37335C6.93072 9.5513 6.35709 9.57692 5.8087 9.44733C5.26032 9.31774 4.75883 9.03807 4.36038 8.63962C3.96193 8.24117 3.68226 7.73968 3.55267 7.1913C3.42308 6.64291 3.4487 6.06928 3.62665 5.53463C3.80459 4.99997 4.12785 4.5254 4.56023 4.16406C4.9926 3.80271 5.51702 3.56882 6.07477 3.48862Z"/>
        <path d="M6.94257 0V3.48865C7.59094 3.58144 8.19174 3.88195 8.65488 4.34509C9.11802 4.80822 9.41852 5.40903 9.51132 6.0574H13C12.8946 4.48555 12.2226 3.00528 11.1087 1.89132C9.99469 0.777353 8.51442 0.105343 6.94257 0Z" />
    </svg>
</template>

<script>
    export default {
        name: 'DashboardIcon',
        props: {
            size: {
                type: [Number, String],
                default: 13
            }
        }
    }
</script>
